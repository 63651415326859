import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Hero, Team, Testimonials, Footer, WhatsApp } from '~/components'
import { useApi } from '~/siteApi'
import Banner from '~/assets/img/Banner-corretor.jpg'

interface Context {
  commercials: Array<never>
  briefs: Array<never>
}

const BrokerPage = ({ location, pageContext }: PageProps) => {
  const { commercials, briefs: testimonials }: Context = useApi(
    pageContext,
    'page-broker'
  )
  return (
    <>
      <Head
        location={location}
        title={`Seja um corretor - ${process.env.GATSBY_SITE_NAME}`}
      />
      <Hero
        image={Banner}
        title="Seja um corretor"
        subtitle="Fale com a Aikon"
      />
      <Team team="Comercial Aikon" mates={commercials} />
      <Testimonials form={false} testimonials={testimonials} />
      <Footer />
      <WhatsApp />
    </>
  )
}

export default BrokerPage
